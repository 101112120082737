import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BadgeColor,
  BaseForm,
  ChartViewConfig,
  ComplianceReportDto,
  getEnumOptions,
  ModuleKeywords,
} from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { ComplianceReportMappingService } from '@shared/services/mappings/framework/compliance-report-mapping.service';

@Component({
  selector: 'app-compliance-report-item-form',
  templateUrl: './compliance-report-item-form.component.html',
  styleUrls: ['./compliance-report-item-form.component.scss'],
})
export class ComplianceReportItemFormComponent extends BaseForm<ComplianceReportDto> implements OnInit {
  chartViewConfig = ChartViewConfig;
  badgeColors = BadgeColor;
  complianceReportTypesList = getEnumOptions(ComplianceReportDto.ComplianceReportTypeEnum);
  complianceReportTypes = ComplianceReportDto.ComplianceReportTypeEnum;
  currentCompliaceType;
  constructor(
    public viewModeService: ViewModeService,
    public mapperService: ComplianceReportMappingService
  ) {
    super(viewModeService, ModuleKeywords.ComplianceReport);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      scopeItems: new FormControl(null, Validators.required),
      complianceReportType: new FormControl(null, Validators.required),
      frequency: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
    this.formGroup.get('complianceReportType').valueChanges.subscribe((type) => {
      if (this.fieldViewMode == 'create') {
        this.formGroup.controls.scopeItems.reset();
      }
      this.currentCompliaceType = type;
    });
  }
}
